/*
"@angular/material/prebuilt-themes/indigo-pink.css",
https://levelup.gitconnected.com/defining-your-own-theme-in-angular-material-8a4a6ffad400
https://m1.material.io/style/color.html#
*/
@use '@angular/material' as mat;
@include mat.core();
.healent {
  --accent: #2b5adc;
  --primary: #152145;
  --dangerous: #ee4266;
  --safe: #1b998b;
  --warning: #f4d35e;
  --warning-shade-1: #f58349;
  --secondary_shade_1: #a1a3b3;
  --secondary_shade_2: #d6d7dd;
  --secondary_shade_3: #eeeff5;
  --secondary_shade_4: #f7f8fc;
  --secondary_shade_5: #ffffff;
}

$accent: var(--accent);
$primary: var(--primary);
$dangerous: var(--dangerous);
$safe: var(--safe);
$warning: var(--warning);
$warning-shade-1: var(--warning-shade-1);
$secondary_shade_1: var(--secondary_shade_1);
$secondary_shade_2: var(--secondary_shade_2);
$secondary_shade_3: var(--secondary_shade_3);
$secondary_shade_4: var(--secondary_shade_4);
$secondary_shade_5: var(--secondary_shade_5);

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.define-typography-level(48px, 48px, 400, 'Roboto', 0em),
  display-1: mat.define-typography-level(32px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.define-typography-level(32px, 32px, 400, 'Roboto', 0em),
  title: mat.define-typography-level(24px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.define-typography-level(18px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.define-typography-level(16px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.define-typography-level(16px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.define-typography-level(16px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.define-typography-level(16px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px),
  h1: mat.define-typography-level(48px, 48px, 400, 'Roboto', 0em),
  h2: mat.define-typography-level(32px, 32px, 400, 'Roboto', 0em),
  h3: mat.define-typography-level(24px, 26px, 400, 'Roboto', 0em),
  h4: mat.define-typography-level(20px, 23px, 400, 'Roboto', 0em),
  h5: mat.define-typography-level(16px, 18px, 400, 'Roboto', 0em),
  h6: mat.define-typography-level(14px, 16px, 400, 'Roboto', 0em)
);

// Define the primary palette
$primary-palette: (
  50: $primary,
  100: $primary,
  200: $primary,
  300: $primary,
  400: $primary,
  500: $primary,
  600: $primary,
  700: $primary,
  800: $primary,
  900: $primary,
  A100: $primary,
  A200: $primary,
  A400: $primary,
  A700: $primary,
  contrast: (
    50: $secondary_shade_5,
    100: $secondary_shade_5,
    200: $secondary_shade_5,
    300: $secondary_shade_5,
    400: $secondary_shade_5,
    500: $secondary_shade_5,
    600: $secondary_shade_5,
    700: $secondary_shade_5,
    800: $secondary_shade_5,
    900: $secondary_shade_5,
    A100: $secondary_shade_5,
    A200: $secondary_shade_5,
    A400: $secondary_shade_5,
    A700: $secondary_shade_5
  )
);
// Define the accent palette using your custom $accent color
$accent-palette: (
  50: $accent,
  100: $accent,
  200: $accent,
  300: $accent,
  400: $accent,
  500: $accent,
  600: $accent,
  700: $accent,
  800: $accent,
  900: $accent,
  A100: $accent,
  A200: $accent,
  A400: $accent,
  A700: $accent,
  contrast: (
    50: $secondary_shade_5,
    100: $secondary_shade_5,
    200: $secondary_shade_5,
    300: $secondary_shade_5,
    400: $secondary_shade_5,
    500: $secondary_shade_5,
    600: $secondary_shade_5,
    700: $secondary_shade_5,
    800: $secondary_shade_5,
    900: $secondary_shade_5,
    A100: $secondary_shade_5,
    A200: $secondary_shade_5,
    A400: $secondary_shade_5,
    A700: $secondary_shade_5
  )
);

// Define the warn palette
$warn-palette: (
  50: $warning,
  100: $warning,
  200: $dangerous,
  300: $dangerous,
  400: $warning,
  500: $dangerous,
  600: $warning,
  700: $dangerous,
  800: $warning,
  900: $dangerous,
  A100: $warning,
  A200: $dangerous,
  A400: $warning,
  A700: $dangerous,
  contrast: (
    50: $secondary_shade_5,
    100: $secondary_shade_5,
    200: $secondary_shade_5,
    300: $secondary_shade_5,
    400: $secondary_shade_5,
    500: $secondary_shade_5,
    600: $secondary_shade_5,
    700: $secondary_shade_5,
    800: $secondary_shade_5,
    900: $secondary_shade_5,
    A100: $secondary_shade_5,
    A200: $secondary_shade_5,
    A400: $secondary_shade_5,
    A700: $secondary_shade_5
  )
);
$primary-palette: mat.define-palette($primary-palette);
$accent-palette: mat.define-palette($accent-palette);
$warn-palette: mat.define-palette($warn-palette);

// Define the theme
$Healent: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
      background: $secondary_shade_4,
      secondary_shade_1: $secondary_shade_1,
      secondary_shade_2: $secondary_shade_2,
      secondary_shade_3: $secondary_shade_3,
      secondary_shade_4: $secondary_shade_4,
      secondary_shade_5: $secondary_shade_5
    ),
    typography: $fontConfig
  )
);

@include mat.all-component-themes($Healent);

// Define classes colors
$colors: (
  'primary': $primary,
  'accent': $accent,
  'dangerous': $dangerous,
  'safe': $safe,
  'warning': $warning,
  'warning-shade-1': $warning-shade-1,
  'secondary-shade-1': $secondary_shade_1,
  'secondary-shade-2': $secondary_shade_2,
  'secondary-shade-3': $secondary_shade_3,
  'secondary-shade-4': $secondary_shade_4,
  'secondary-shade-5': $secondary_shade_5
);

// Iterate through the colors and generate text color classes
@each $name, $color in $colors {
  .text-#{$name} {
    color: $color;
  }
}

// Iterate through the colors and generate background color classes
@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }
}

// Define sizes
$sizes: (
  'xxl': 120px,
  'xl': 60px,
  'l': 30px,
  'm': 20px,
  'xm': 15px,
  's': 10px,
  'xs': 5px,
  'xxs': 2px
);

// Mixin for generating margin and padding classes
@mixin generate-utility-classes($property, $directions) {
  @each $name, $size in $sizes {
    @each $dir in $directions {
      .#{$property}-#{$dir}-#{$name} {
        #{$property}-#{$dir}: $size !important;
      }
    }
    // Generate classes for all sides if needed
    .#{$property}-#{$name} {
      #{$property}: $size !important;
    }
  }
  // Generate classes for zero value
  .#{$property}-0 {
    #{$property}: 0 !important;
  }
  @each $dir in $directions {
    .#{$property}-#{$dir}-0 {
      #{$property}-#{$dir}: 0 !important;
    }
  }
}

// Directions shorthand
$all-directions: (top, right, bottom, left);
$margin-padding-directions: (top, right, bottom, left, '');

// Generate margin and padding utility classes
@include generate-utility-classes(margin, $margin-padding-directions);
@include generate-utility-classes(padding, $margin-padding-directions);

// flex class
.flex {
  display: flex;
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}
.space-between {
  justify-content: space-between;
}

// Generate styles
body {
  background-color: $secondary_shade_4;
}
a {
  color: $accent;
}
.curser-pointer {
  cursor: pointer;
}
.hide {
  display: none !important;
}
.nowrap {
  white-space: nowrap !important;
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(
    --secondary_shade_2
  ) !important;
}
// Toolbar
.mat-toolbar {
  padding: 0 map-get($sizes, l) !important;
}
// Cards styles
.padding-0 {
  .mdc-card {
    .mat-mdc-card-content {
      padding: 0;
    }
  }
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--accent);
  --mdc-protected-button-label-text-color: var(--secondary_shade_5);
  --mat-protected-button-state-layer-color: var(--secondary_shade_5);
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}

// Generate table styles
.table-container {
  position: relative;
  table {
    thead {
      position: sticky;
      top: 0;
      background: $secondary_shade_5;
      z-index: 2;
      th {
        white-space: nowrap;
      }
      td {
        white-space: pre-line;
        .mdc-button__label {
          white-space: nowrap;
        }
      }
    }
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: $secondary_shade_3;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    height: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border: 1px solid $primary;
  }
}
.container-full {
  display: flex;
  width: 100%;
  height: calc(100svh - 64px);
}
.flex-1 {
  flex: 1;
}
.height-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
// Forms and inputs styles
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  height: 0;
}

.flag-icon {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-16 {
  height: 20px !important;
  width: 20px !important;
  font-size: 20px;
}
.priority-critical {
  background-color: $dangerous;
}

.priority-high {
  background-color: $warning_shade_1;
}

.priority-normal {
  background-color: $secondary_shade_1;
}
